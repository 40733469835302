import $http from '@axios'

export default {
  namespaced: true,
  state: {
    totalBuy: 0,
    is_selected_customer: false,
    po_number: '',
    discount: '0',
    cartUuid: '',
    itemUuid: '',
    resultCartActive: {},
    discountCart: 0,
    discountType: null,
    totalPrice: 0,
    detailCart: {},
    isEdit: false,
    discountTotal: 0,
    subtotalCart: 0,
    key: 0,
    formAdditionalCost: [],
    totalAdditionalCost: 0,
    totalTransaksi: 0,
  },
  getters: {},
  mutations: {
    SET_TOTAL_TRANSAKSI(state, value) {
      state.totalTransaksi = value
    },
    SET_TOTAL_ADDITIONAL_COST(state, value) {
      state.totalAdditionalCost = value
    },
    SET_FORM_ADDITIONAL_COST(state, value) {
      state.formAdditionalCost = value
    },
    setKey(state, value) {
      state.key = value
    },
    setDiscountTotal(state, value) {
      state.discountTotal = value
    },
    setSubtotalCart(state, value) {
      state.subtotalCart = value
    },
    setIsEdit(state, value) {
      state.isEdit = value
    },
    setDetailCart(state, value) {
      state.detailCart = value
    },
    setItemUuid(state, value) {
      state.itemUuid = value
    },
    setSelectedCustomer(state, value) {
      state.is_selected_customer = value
    },
    setDiscount(state, value) {
      state.discount = value
    },
    setProduct(state, value) {
      state.totalBuy = value
    },
    setCartUuid(state, value) {
      state.cartUuid = value
    },
    setResultCartActive(state, value) {
      state.resultCartActive = value
    },
    setDiscountCart(state, value) {
      state.discountCart = value
    },
    setDiscountType(state, value) {
      state.discountType = value
    },
    setTotalBuy(state, value) {
      state.totalBuy = value
    },
    setTotalPrice(state, value) {
      state.totalPrice = value
    },
  },
  actions: {
    getList(context, { params }) {
      return new Promise((resolve, reject) => {
        $http.get('/cashier/cart/list', { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getCart(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/cashier/cart/detail/${uuid}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    updateQtyCartItem(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/cashier/cart/edit-qty-item/${uuid}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    updateCart(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/cashier/cart/update/${uuid}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    saveCart(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/cashier/cart/save/${uuid}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getNextQueueNumber() {
      return new Promise((resolve, reject) => {
        $http.get('/cashier/cart/next-queue')
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    deleteCart(context, { uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/cashier/cart/delete/${uuid || ''}`)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    removeCartByCartUuid(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/cashier/cart/delete-item/${uuid || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    addDiscountToCartByCart(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/cashier/cart/add-discount/${uuid || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    addAddtionalCost(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/cashier/cart/add-additional-fee/${uuid || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    removeAddtionalCost(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/cashier/cart/remove-additional-fee/${uuid || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    removeDiscountFromCartByCart(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/cashier/cart/remove-discount/${uuid || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postAddItemToCart(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/cashier/cart/add-item/${uuid || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postEditItemToCart(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/cashier/cart/edit-item/${uuid || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postData(context, { payload, uuid, type }) {
      return new Promise((resolve, reject) => {
        $http.post(`/cashier/product/${uuid || ''}/${type}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
