import $http from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    getListOrderNote(context, { params }) {
      return new Promise((resolve, reject) => {
        $http
          .get(`/order-note`, { params })
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getDetailOrderNote(context, { uuid }) {
      return new Promise((resolve, reject) => {
        $http
          .get(`/order-note/${uuid}`)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createOrderNote(context, { payload }) {
      return new Promise((resolve, reject) => {
        $http
          .post(`/order-note`, payload)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateOrderNote(context, { id, payload }) {
      return new Promise((resolve, reject) => {
        $http
          .put(`/order-note/${id}`, payload)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteData(context, { id }) {
      return new Promise((resolve, reject) => {
        $http
          .delete(`/order-note/${id}`)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
