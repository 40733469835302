function lazyLoad(view){
  return() => import(`@/views/master/${view}.vue`)
}
export default [
  {
    path: '/master/toko',
    name: 'master-toko.index',
    component: lazyLoad('toko/index'),
    meta: {
    },
  },
  {
    path: '/master/sales',
    name: 'master-sales.index',
    component: lazyLoad('sales/index'),
    meta: {
    },
  },
  {
    path: '/master/produk',
    name: 'master-product.index',
    component: lazyLoad('product/index'),
    meta: {
    },
  },
  {
    path: '/master/produk-induk/:id',
    name: 'master-product.detail',
    component: lazyLoad('product/parent'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/master/produk-induk',
    name: 'master-product.parent',
    component: lazyLoad('product/parent'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/master/produk-eceran',
    name: 'master-product.retail',
    component: lazyLoad('product/retail'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/master/produk-eceran/:id',
    name: 'master-product-eceran.detail',
    component: lazyLoad('product/retail'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/master/gudang',
    name: 'master-gudang.index',
    component: lazyLoad('gudang/index'),
    meta: {
    },
  },
  {
    path: '/master/users',
    name: 'master-users.index',
    component: lazyLoad('users/index'),
    meta: {
    },
  },
  {
    path: '/master/cabang',
    name: 'master-cabang.index',
    component: lazyLoad('cabang/index'),
    meta: {
    },
  },
  {
    path: '/master/rekening',
    name: 'master-rekening.index',
    component: lazyLoad('rekening/index'),
    meta: {
    },
  },
  {
    path: '/master/billing',
    name: 'master-billing.index',
    component: lazyLoad('billing/index'),
    meta: {
    },
  },
  {
    path: '/master/order-note',
    name: 'order-note.index',
    component: lazyLoad('order-note/index'),
    meta: {
    },
  },
  {
    path: '/master/order-note/:id',
    name: 'order-note.detail',
    component: lazyLoad('order-note/detail'),
    meta: {
      auth: true,
      layout: "full",
    },
  },
]
