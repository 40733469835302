import $http from "@axios";

export default {
  namespaced: true,
  state: {
    editId: null,
    formPayload: {
      po_application_id: null,
      booking_date: "",
      items: [
        {
          product_id: "",
          qty: 0,
          is_need_negotiation: 0,
          price: 0,
          discounts: 0,
        },
      ],
    },
    result: {},
    updateData: false,
  },
  getters: {},
  mutations: {
    setUpdateData(state, value) {
      state.updateData = value;
    },
    // setFormPayloadTopup(state, value) {
    //   state.formPayloadTopup = value;
    // },
    // setFormPayloadPiutang(state, value) {
    //   state.formPayloadPiutang = value;
    // },
    // setIsUtang(state, value) {
    //   state.isUtang = value;
    // },
    // setDetailPiutang(state, value) {
    //   state.detailPiutang = value;
    // },
    // setCustomer(state, value) {
    //   state.customer = value;
    // },
    setEditId(state, value) {
      state.editId = value;
    },
    setFormPayload(state, value) {
      state.formPayload = value;
    },
    setResult(state, value) {
      state.result = value;
    },
  },
  actions: {
    getData(context, { params }) {
      return new Promise((resolve, reject) => {
        $http
          .get("/purchase-orders/purchase/booking", { params })
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getDetail(context, { uuid }) {
      return new Promise((resolve, reject) => {
        $http
          .get(`/purchase-orders/purchase/booking/${uuid || ""}`)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getProduct(context, { uuid }) {
      return new Promise((resolve, reject) => {
        $http
          .get(`/purchase-orders/purchase/booking/${uuid || ""}/list-product`)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    postData(context, { payload, query }) {
      return new Promise((resolve, reject) => {
        $http
          .post(`/purchase-orders/purchase/booking${query || ""}`, payload)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteData(context, query) {
      return new Promise((resolve, reject) => {
        $http
          .delete(`/purchase-orders/purchase/booking${query || ""}`)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    clearItem(context) {
      context.commit("setEditId", null);
      context.commit("setFormPayload", {
        po_application_id: null,
        booking_date: "",
        items: [
          {
            product_id: "",
            qty: 0,
            is_need_negotiation: 0,
            price: 0,
            discounts: 0,
          },
        ],
      });
    },
  },
};
