import $http from "@axios";

export default {
  namespaced: true,
  state: {
    editId: null,
    formPayload: {
      target_merchant_id: "",
      store_name: "",
      owner_name: "",
      phone_number: "",
      phone_number_country: "",
      address: "",
      ktp_photo: "",
      npwp_photo: "",
      shop_photo: "",
      selectedCountry: {
        country_code: "ID",
        country_flag: "https://flagsapi.com/ID/shiny/64.png",
        country_name: "Indonesia",
        dial_code_preview: "+62",
        dial_code_value: "62",
      },
      payment_type: null,
      limit: null,
    },
    result: {},
    updateData: false,
  },
  getters: {},
  mutations: {
    setUpdateData(state, value) {
      state.updateData = value;
    },
    // setFormPayloadTopup(state, value) {
    //   state.formPayloadTopup = value;
    // },
    // setFormPayloadPiutang(state, value) {
    //   state.formPayloadPiutang = value;
    // },
    // setIsUtang(state, value) {
    //   state.isUtang = value;
    // },
    // setDetailPiutang(state, value) {
    //   state.detailPiutang = value;
    // },
    // setCustomer(state, value) {
    //   state.customer = value;
    // },
    setEditId(state, value) {
      state.editId = value;
    },
    setFormPayload(state, value) {
      state.formPayload = value;
    },
    setResult(state, value) {
      state.result = value;
    },
  },
  actions: {
    getData(context, { params }) {
      return new Promise((resolve, reject) => {
        $http
          .get("/purchase-orders/purchase/application", { params })
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getDataMerchant(context) {
      return new Promise((resolve, reject) => {
        $http
          .get("/purchase-orders/purchase/application/list-merchant")
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getDetail(context, { uuid }) {
      return new Promise((resolve, reject) => {
        $http
          .get(`/purchase-orders/purchase/application/${uuid || ""}/detail`)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    postData(context, { payload, query }) {
      return new Promise((resolve, reject) => {
        $http
          .post(`/purchase-orders/purchase/application${query || ""}`, payload)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteData(context, query) {
      return new Promise((resolve, reject) => {
        $http
          .delete(`/purchase-orders/purchase/application${query || ""}`)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    clearItem(context) {
      context.commit("setEditId", null);
      context.commit("setFormPayload", {
        target_merchant_id: "",
        store_name: "",
        owner_name: "",
        phone_number: "",
        phone_number_country: "",
        address: "",
        ktp_photo: "",
        npwp_photo: "",
        shop_photo: "",
      });
    },
  },
};
